import { createGlobalState } from '@vueuse/core'

import { useApi } from '@src/hooks/useApi.hook'
import { useCrudApi } from '@src/hooks/useCrudApi.hook'

import { type AdvertiserId } from '@src/types/api/advertiser.type'
import {
  type Cabinet,
  type CabinetId,
  type CabinetMassCreationItem,
  type CabinetParams,
  type CreateCabinet,
  type UpdateCabinet,
} from '@src/types/api/cabinet.type'
import {
  type CabinetManager,
  type CreateCabinetManager,
  type DestroyCabinetManager,
} from '@src/types/api/cabinetManager.type'
import { type BaseResponse } from '@src/types/api/common.type'

export const useCabinetApi = createGlobalState(() => {
  const url = '/v1/cabinets'

  const api = useApi()

  function refill(cabinetId: CabinetId, amount: number, promoCode: string) {
    return api.post<any, void>(`${url}/${cabinetId}/refill`, { amount, promoCode })
  }

  function refund(cabinetId: CabinetId, amount: number) {
    return api.post<any, void>(`${url}/${cabinetId}/return`, { amount })
  }

  function cancelCreation(cabinetId: CabinetId) {
    return api.post<any, void>(`${url}/${cabinetId}/cancel-creation`)
  }

  function acceptCabinet(cabinetId: CabinetId) {
    return api.post<any, BaseResponse<Cabinet>>(`${url}/${cabinetId}/accept`)
  }

  function cabinetsCount() {
    return api.get<any, BaseResponse<number>>(`${url}/count`)
  }

  function createManager(cabinetId: CabinetId, data: CreateCabinetManager) {
    // Особенности VK API, возвращает всегда весь список.
    return api.post<any, BaseResponse<CabinetManager[]>>(`${url}/${cabinetId}/add-manager`, data)
  }

  function destroyManager(cabinetId: CabinetId, data: DestroyCabinetManager) {
    return api.post<any, void>(`${url}/${cabinetId}/remove-manager`, data)
  }

  function massCreate(advertiserId: AdvertiserId, vkProfile: string, data: CabinetMassCreationItem[]) {
    return api.post<any, void>(`${url}/mass-creation`, { advertiserId, vkProfile, data })
  }

  function getAttach(cabinetId: CabinetId, num: number) {
    return api.get<any, Blob>(`${url}/${cabinetId}/get-attach/${num}`)
  }

  return {
    ...useCrudApi<Cabinet, CreateCabinet, UpdateCabinet, CabinetParams>(url, { withGrantor: true }),
    refill,
    refund,
    createManager,
    destroyManager,
    acceptCabinet,
    massCreate,
    getAttach,
    cancelCreation,
    cabinetsCount,
  }
})
